import React, { useCallback, useEffect, useRef, useState } from 'react';
import L, { LatLngExpression, LeafletMouseEvent, polygon } from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import turf, { Feature, featureCollection, feature, Geometry, Polygon, FeatureCollection, union, MultiPolygon, geometry } from '@turf/turf';
import { AppDispatch, RootState } from '../store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { baseStyle, defaultZoomLevel, hybridLayerParams, maxBoundsContinentalUSParams, streetsLayerParams, satelliteLayerParams, selectedOpacity } from './Shared/MapCommon';
import { IFieldResponse } from '../Models/Responses/FieldResponse';
import
{
	addSingleSelectedField,
	clearSelectedFields,
	MapInteractionMode,
	setMapInteractionMode,
	setDrawnFeatures,
	DrawingInteractionMode,
	IInteractionMode,
	setShowMapSearch,
	IDrawingInterfaceMode
} from '../store/UI/UISlice';
import { IFieldSoilData, MapPopUp } from './PopUp/MapPopUp';
import { get } from 'lodash';
import { DrawingTray } from './DrawingTray';
import { ZoomTray } from './ZoomTray';
import { ICLUItem } from '../Models/Responses/CLUResponse';
import { getCLUs, addSelectedCLU, clearState as clearCluState } from '../store/CLUs/CLUSlice';
import { getSoilData } from '../store/SoilData/SoilDataSlice';
import { ICLURequest } from '../Models/Requests/CLURequest';
import { notification } from 'antd';
import { ISoilDataResponse } from '../Models/Responses/SoilDataResponse';
import { colorToHex } from '../Utility/Utils';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export interface IWeatherClimatology {
	StartYear:                 number;
	EndYear:                   number;
	MaximumTemperatureCelsius: number;
	MinimumTemperatureCelsius: number;
	CornGrowingDegreeUnits:    number;
	PrecipitationMillimeters:  number;
}


export interface IWeatherSnapshot {
	Climatology:				   IWeatherClimatology | undefined;
	TprFine:                       number;
	TprCourse:                     number;
	CropRiskFineSoil:              number;
	CropRiskCourseSoil:            number;
	Year:                          number;
	DayOfYear:                     number;
	Datestamp:                     string;
	MaximumTemperatureSoilCelsius: number;
	MinimumTemperatureSoilCelsius: number;
	PrecipitationMillimeters:      number;
	MinimumTemperature2mCelsius:   number;
	MaximumTemperature2mCelsius:   number;
	WindGustKmPerHour:             number;
	CornGrowingDegreeUnits:        number;
	WindGustRisk:                  number;
}

export interface IGrowthStageData {
	FieldId:      string;
	PlantingDate: Date;
	GrowthStages: IGrowthStages;
}

export interface IGrowthStages {
	Germination: Date;
	R1:          Date;
	R2:          Date;
	R3:          Date;
	R4:          Date;
	R5:          Date;
	R6:          Date;
	V1:          Date;
	V10:         Date;
	V11:         Date;
	V12:         Date;
	V13:         Date;
	V14:         Date;
	V2:          Date;
	V3:          Date;
	V4:          Date;
	V5:          Date;
	V6:          Date;
	V7:          Date;
	V8:          Date;
	V9:          Date;
	VE:          Date;
	VT:          Date;
}


/**
 * In case a map marker is ever needed, this is a workaround, otherwise it will fail to get
 * the marker icon file properly.
 * Ref: https://github.com/PaulLeCam/react-leaflet/issues/453
 */
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useTheme } from 'styled-components';
import { Api } from '../Api/Api';
import { getCurrentActingUser } from '../store/User/AuthSlice';

const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
/**
 * end map marker solution
 */

interface IMapProps extends PropsFromRedux
{

}

const MapComponent = (props: IMapProps) =>
{
	const {
		DisplayMapSearch,
		DrawnFeatures,
		Growers,
		IsDeletingField,
		IsLoadingCLUData,
		IsLoadingFields,
		IsLoadingSoilData,
		IsSavingField,
		MapboxToken,
		MapMode,
		SelectedCLUs,
		SelectedFieldForEdit,
		SelectedFieldIds,
		SelectedGrowerId,
		SelectedUserId,
		AuthToken,
		ChangeMapMode,
		ClearCluState,
		DownloadCLUs,
		DownloadSoilMap,
		SelectSingleField,
		SetSelectedCLU,
		SetShowMapSearch,
		StoreDrawnFeatures,
	} = props;
	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);
	const [allGrowerFields, setAllGrowerFields] = useState<IFieldResponse[]>([]);
	const [popUpField, setPopUpField] = useState<IFieldResponse>(undefined);
	const [popUpFarmName, setPopUpFarmName] = useState<string>('');
	const [cluData, setCluData] = useState<ICLUItem[]>([]);
	const [fieldSoilData, setFieldSoilData] = useState<{ [key: string] : FeatureCollection<Geometry> }>({});
	const [showSoilMapButton, setShowSoilMapButton] = useState<boolean>(true);

	const theme = useTheme();

	// Reference to the map control
	const mapRef = useRef<L.DrawMap | undefined>(undefined);

	// TODO: For future popups: Reference to the div of any popup
	const [popupRef, setPopupRef] = useState<HTMLDivElement | undefined>(undefined);

	// Store the element the map will be rendered into
	const [mapContainer, setMapContainer] = useState<HTMLDivElement>();

	// Store the geojson data layer we will use to represent everything.
	const [geoJsonLayer] = useState(L.geoJSON());
	const [cluLayer] = useState(L.geoJSON());
	const [soilDataLayer] = useState(L.geoJSON());
	// Single field edit layer
	const [editFieldLayer] = useState(L.geoJSON());

	// Base Layers for the map
	const hybridLayer = L.tileLayer((hybridLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), hybridLayerParams[1] as {attribution: string});
	const streetsLayer = L.tileLayer((streetsLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), streetsLayerParams[1] as {attribution: string});
	const satelliteLayer = L.tileLayer((satelliteLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), satelliteLayerParams[1] as {attribution: string});

	// Create a layer object to pass to the layer control on the map
	const baseMaps = {
		'Hybrid': hybridLayer,
		'Streets': streetsLayer,
		'Satellite': satelliteLayer
	};

	// Continental US bounds
	const maxBoundsContinentalUS: L.LatLngBounds =  L.latLngBounds(
		L.latLng(maxBoundsContinentalUSParams[0][0], maxBoundsContinentalUSParams[0][1]), //Southwest
		L.latLng(maxBoundsContinentalUSParams[1][0], maxBoundsContinentalUSParams[1][1])  //Northeast
	);

	// Create the styler for the CLU Layer
	const cluStyler = ((feature: Feature<Geometry, ICLUItem>) =>
	{
		if (MapMode.mode === MapInteractionMode.CLU)
		{
			if (feature.properties && SelectedCLUs && SelectedCLUs.find((cluItem: ICLUItem) => cluItem.fid === feature.properties?.fid))
			{
				return {
					...baseStyle(theme),
					fillColor: theme.colors.primary,
					fillOpacity: selectedOpacity
				};
			}

			return {
				...baseStyle(theme),
				color: theme.colors.blueLM,
				fillColor: theme.colors.transparentWhite
			};
		}
	}) as L.StyleFunction<any>;

	// Create the styler for the SoilData Layer
	const soilDataStyler = ((feature: Feature<Geometry>) =>
	{
		if (feature.properties)
		{
			const color = feature.properties.color;
			const hexColor = colorToHex(color);
			return {
				...baseStyle(theme),
				fillColor: hexColor,
				fillOpacity: selectedOpacity,
			};
		}

		return {
			...baseStyle(theme),
			color: theme.colors.blueLM,
			fillColor: theme.colors.transparentWhite
		};
	}) as L.StyleFunction<any>;

	// Create the styler for the Editable Layer
	const editableStyler = ((feature: Feature<Geometry>) =>
	{
		return {
			...baseStyle(theme),
			fillColor: theme.colors.blue,
			color: theme.colors.blueLM,
		};
	}) as L.StyleFunction<any>;

	// Create the default map styler that will apply color to the rendered vectors.
	const styler = ((feature: Feature<Geometry, IFieldResponse & { isDrawn: Boolean }>) =>
	{
		if (MapMode.mode !== MapInteractionMode.Selection)
		{
			if (feature.properties?.isDrawn)
			{
				return { ...baseStyle(theme) };
			}
			return {
				...baseStyle(theme),
				fillColor: theme.colors.mediumGrey
			};
		}
		else 
		{
			if (feature.properties && SelectedFieldIds && SelectedFieldIds.find((sf: string) => sf === feature.properties?.Id))
			{
				return {
					...baseStyle(theme),
					fillColor: theme.colors.primary,
					fillOpacity: selectedOpacity
				};
			}
			return { ...baseStyle(theme) };
		}

	}) as L.StyleFunction<any>;

	geoJsonLayer.setStyle(styler);
	editFieldLayer.setStyle(editableStyler);

	// When the container is rendered, save the element so we can install the map there
	const installMap = useCallback((ref: HTMLDivElement) =>
	{
		if (ref)
		{
			// On refreshes, if the mapRef exists, it can throw an error about the map already having been initialized
			// Removing it before we re-initialize it will fix that
			if (mapRef.current)
			{
				mapRef.current.remove();
			}

			if (!selectedGrower || !selectedGrower.ReferencePoint)
			{
				mapRef.current = L.map(ref, {
					zoomControl: false,
					layers: [hybridLayer], // default to the Streets layer
				}).fitBounds(maxBoundsContinentalUS);
			}
			else
			{
				const growerReferencePoint = selectedGrower.ReferencePoint.coordinates as turf.Position;
				mapRef.current = L.map(ref, {
					zoomControl: false,
					zoom: defaultZoomLevel,
					center: [growerReferencePoint[1], growerReferencePoint[0]],
					layers: [hybridLayer] // default to the Streets layer
				});
			}

			// Layer control - defaults to topright
			L.control.layers(baseMaps).addTo(mapRef.current);

			// Zoom control
			L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);

			setMapContainer(ref);
		}
	}, [setMapContainer]);

	// When the popup actually renders, this is called and allows us to store the popup div.  We actually
	// store the inner containing element so we can later 'portal' to it.
	const popupWasOpened = useCallback((e: any) =>
	{
		setPopupRef(((e.popup._wrapper as HTMLDivElement)?.getElementsByClassName('mapPopupView').item(0) as HTMLDivElement) ?? undefined);
	}, [setPopupRef]);

	useEffect(() =>
	{
		if (!IsLoadingFields && !IsSavingField && !IsDeletingField)
		{
			setAllGrowerFields(selectedGrower ? selectedGrower.Farms?.flatMap(fa => fa.Fields) : []);
		}
	}, [selectedGrower, IsLoadingFields, IsSavingField, IsDeletingField]);

	// When our display geometry changes (or the state changes such that the display should too)
	// we remove all geojson data and recreate it.
	useEffect(() =>
	{
		if (MapMode.mode === MapInteractionMode.Drawing && MapMode.drawingMode === DrawingInteractionMode.Deleting)
		{
			return;
		}

		geoJsonLayer.clearLayers();
		soilDataLayer.clearLayers();
		editFieldLayer.clearLayers();

		if (MapMode.mode === MapInteractionMode.Drawing && DrawnFeatures)
		{
			geoJsonLayer.setStyle(styler);
		}

		if (IsLoadingFields || IsSavingField || IsDeletingField)
			return;

		if (SelectedGrowerId)
		{
			if (mapRef.current && allGrowerFields?.length > 0)
			{
				// Get all of the boundaries
				// There are some cases where the Fields have no Boundary, this may only be during
				// early development
				const fieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
				for (let i = 0, len = allGrowerFields.length; i < len; i++)
				{
					const field = allGrowerFields[i];
					if (field && SelectedFieldForEdit && field.Id === SelectedFieldForEdit)
					{
						continue;
					}
					if (field.Boundary)
					{
						fieldBoundaries.push(feature(field.Boundary, field));
					}
				}
				if (fieldBoundaries.length > 0)
				{
					const featureLayer = geoJsonLayer.addData(featureCollection(fieldBoundaries)).bindTooltip(function (layer: L.Layer)
					{
						const name = get(layer, 'feature.properties.Name', '');
						const acres = Number(get(layer, 'feature.properties.Area', '')).toFixed(1);
						return name + '<br>' + 'Acres: ' + acres;
					}) as L.GeoJSON<any>;

					if (MapMode.mode !== MapInteractionMode.Drawing)
					{
						// Only zoom to fit all fields on the first draw, selecting a field should not zoom to all fields again
						if (SelectedFieldIds?.length === 0 && MapMode.mode !== MapInteractionMode.CLU)
						{
							// Padding will scoot the map over a bit to give space for the overlay on the left
							mapRef.current.fitBounds(featureLayer.getBounds(), { paddingTopLeft: [400, 0] });
						}
						else
						{
							onCenterSelected();
						}

						// Hook into the user clicking on this feature and potentially bubble up the interaction
						featureLayer.on('click', (l: LeafletMouseEvent) =>
						{
							// Make sure soil data layer clears
							soilDataLayer.clearLayers();

							const latlng = l.latlng;
							const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, IFieldResponse>;
							const fieldId = clickedFeature.properties.Id;
							if (SelectedFieldIds.some(id => id === fieldId))
							{
								// we have to set the width here to make sure the popup fits on the screen
								const popup = l.propagatedFrom.bindPopup(
									'<div class="mapPopupView"><div class="placeholder" style="width:400px;height:400px"></div>',
									{
										keepInView: true,
										closeButton: true,
										className: 'mapPopupView',
										autoPan: true,
										autoPanPadding: [650, 100]
									}
								);

								setPopUpField(clickedFeature.properties);
								setPopUpFarmName(selectedGrower.Farms.find(fa => fa.Id === clickedFeature.properties.FarmId).Name);
								popup.on('popupopen', popupWasOpened);
								popup.openPopup(latlng);
							}
							else
							{
								SelectSingleField(fieldId);
							}
						});
					}

					if (SelectedFieldForEdit && !DrawnFeatures)
					{
						editFieldLayer.options = { pmIgnore: false };
						// Get all of the boundaries
						// There are some cases where the Fields have no Boundary, this may only be during
						// early development
						const fieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
						const foundField = allGrowerFields.find(gf => gf.Id === SelectedFieldForEdit);
						if (foundField)
						{
							if (foundField.Id === SelectedFieldForEdit)
							{
								fieldBoundaries.push(feature(foundField.Boundary, foundField));
							}
						}

						editFieldLayer.addData(featureCollection(fieldBoundaries)) as L.GeoJSON<any>;
						saveDrawnFeatures();

						// This will save the editable features into the DrawnFeatures object and then re-run this function landing in the elseIf statement below
						// This allows any multi-polygons to be broken down into single polygons so they can be deleted if necessary
					}
					else if (SelectedFieldForEdit && DrawnFeatures)
					{
						// Just add the drawn features to the editable layer
						editFieldLayer.addData(DrawnFeatures) as L.GeoJSON<any>;

						// Padding will scoot the map over a bit to give space for the overlay on the left
						mapRef.current.fitBounds(editFieldLayer.getBounds(), { paddingTopLeft: [400, 0] });
					}
					else if (!SelectedFieldForEdit && !DrawnFeatures)
					{
						editFieldLayer.options = { pmIgnore: true };
						editFieldLayer.clearLayers();
					}

					editFieldLayer.setStyle(editableStyler);
					geoJsonLayer.setStyle(styler);

					return () =>
					{
						featureLayer.off('click');
					};
				}
			}
			// As long as we're just looking, reset the map view
			else if (mapRef.current && MapMode.mode === MapInteractionMode.Selection)
			{
				// Without fields to zoom to, use the grower's reference point instead
				if (selectedGrower?.ReferencePoint)
				{
					const growerReferencePoint = selectedGrower.ReferencePoint.coordinates as turf.Position;
					mapRef.current.setView([growerReferencePoint[1], growerReferencePoint[0]], defaultZoomLevel);
				}
				// Failing that, zoom out to the continental US
				else
				{
					mapRef.current.fitBounds(maxBoundsContinentalUS);
				}
			}
		}
		// Without a selected grower, simply zoom out to the continental US
		else if (mapRef.current)
		{
			mapRef.current.fitBounds(maxBoundsContinentalUS);
		}
	}, [IsLoadingFields, IsSavingField, IsDeletingField, allGrowerFields, mapRef.current, popupWasOpened, DrawnFeatures, SelectedFieldForEdit, MapMode, SelectedFieldIds]);

	useEffect(() =>
	{
		if (!mapContainer)
		{
			return;
		}

		// Ignore the geoman controls on all layers - for now
		geoJsonLayer.options = { pmIgnore: true };
		geoJsonLayer.addTo(mapRef.current);
		geoJsonLayer.setStyle(styler);

		cluLayer.options = { pmIgnore: true };
		cluLayer.addTo(mapRef.current);
		cluLayer.setStyle(cluStyler);

		soilDataLayer.options = { pmIgnore: true };
		soilDataLayer.addTo(mapRef.current);
		soilDataLayer.setStyle(styler);

		editFieldLayer.options = { pmIgnore: true };
		editFieldLayer.addTo(mapRef.current);
		editFieldLayer.setStyle(editableStyler);

		// make all layers not intersect during draw  
		mapRef.current.pm.setGlobalOptions(
			{
				allowSelfIntersection: false,
				snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'], // Default
				panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' } // Default
			}
		);

		mapRef.current.pm.addControls({
			position: 'topright',
			drawMarker: false,
			drawCircleMarker: false,
			drawText: false,
			drawPolyline: false
		});

		checkDrawingToolsDisplay();

	}, [mapContainer, mapRef.current]);

	const saveDrawnFeatures = () =>
	{
		if (!SelectedFieldForEdit)
		{
			// Get the current drawn layers
			const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
			
			// Format all of the layers' features to polygons
			const drawnLayersFeatures: FeatureCollection<Polygon> = formatFeaturesToPolys(drawnLayers);
			
			// Store the new drawn polygons
			StoreDrawnFeatures(drawnLayersFeatures);
			ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
		}
		else
		{
			// We have an edited item in the edit layer
			const editedLayers = editFieldLayer.getLayers();
			// Get the current drawn layers
			const geomanLayers: L.Layer[] = mapRef.current.pm.getGeomanLayers() as L.Layer[];
			
			// Format all of the edited layers' features to polygons
			const drawnLayersFeatures: FeatureCollection<Polygon> = formatFeaturesToPolys(geomanLayers);

			// Clear the layers
			geomanLayers.forEach(layer =>
			{
				layer.remove();
			});
			editedLayers.forEach(layer =>
			{
				layer.remove();
			});

			cluLayer.clearLayers();
			editFieldLayer.clearLayers();
			ClearCluState();

			// Store the new drawn polygons
			StoreDrawnFeatures(drawnLayersFeatures);
			ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
		}
	};

	const formatFeaturesToPolys = (layers: L.Layer[]): FeatureCollection<Polygon> =>
	{
		let drawnLayersFeatures: FeatureCollection<Polygon>;
		layers.forEach(drawnLayer =>
		{
			if (drawnLayer instanceof L.Circle)
			{
				const circleLayer = drawnLayer as L.Circle;
				const convertedCircleToPoly = L.PM.Utils.circleToPolygon(circleLayer);
				const circlePoly = convertedCircleToPoly.toGeoJSON().geometry as Polygon;
				drawnLayersFeatures =
					featureCollection([...(drawnLayersFeatures?.features ?? []), feature(circlePoly, { isDrawn: true })]);
			}
			else
			{
				const polyLayer = drawnLayer as L.Polygon;
				const polyLayerPoly = polyLayer.toGeoJSON().geometry as Polygon;
				drawnLayersFeatures =
					featureCollection([...(drawnLayersFeatures?.features ?? []), feature(polyLayerPoly, { isDrawn: true })]);
			}
		});

		// If we have a CLU, do the same
		if (SelectedCLUs && SelectedCLUs.length > 0)
		{
			SelectedCLUs.forEach(cluItem =>
			{
				const cluItemPoly = cluItem.geometry as Polygon;
				drawnLayersFeatures =
					featureCollection([...(drawnLayersFeatures?.features ?? []), feature(cluItemPoly, { isDrawn: true })]);
			});
		}

		if (drawnLayersFeatures)
		{
			let newFeatureCollection: FeatureCollection<Polygon>;
			const unionedFeatures = drawnLayersFeatures.features.reduce((a, b) => union(a, b), drawnLayersFeatures.features[0]);

			if (unionedFeatures.geometry.type.toLowerCase() === 'multipolygon')
			{
				(unionedFeatures as Feature<MultiPolygon>).geometry.coordinates.forEach(p =>
				{
					const newPoly = geometry('Polygon', p) as Polygon;
					newFeatureCollection = featureCollection([...(newFeatureCollection?.features ?? []), feature(newPoly, { isDrawn: true })]);
				});
			}
			else
			{
				newFeatureCollection = featureCollection([...(newFeatureCollection?.features ?? []), feature(unionedFeatures.geometry as Polygon, { isDrawn: true })]);
			}

			drawnLayersFeatures = newFeatureCollection;
		}
		
		return drawnLayersFeatures;
	};

	// Display CLU Data on the clu layer
	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		cluLayer.clearLayers();

		if (!IsLoadingCLUData && cluData && cluData.length > 0)
		{
			// Get all of the CLU boundaries
			// There are some cases where the Fields have no Boundary, this may only be during
			// early development
			const cluBoundaries: Feature<Geometry, ICLUItem>[] = [];
			for (let i = 0, len = cluData.length; i < len; i++)
			{
				const cluField = cluData[i];
				if (cluField.geometry)
				{
					cluBoundaries.push(feature(cluField.geometry, cluField));
				}
			}
			if (cluBoundaries.length > 0)
			{
				if (MapMode.mode === MapInteractionMode.CLU)
				{
					if (cluLayer.getLayers().length === 0)
					{
						cluLayer.addData(featureCollection(cluBoundaries)) as L.GeoJSON<any>;
					}
					// Hook into the user clicking on this feature and potentially bubble up the interaction
					cluLayer.on('click', (l: LeafletMouseEvent) =>
					{
						const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, ICLUItem>;
						const cluItem = { fid: clickedFeature.properties.fid, geometry: clickedFeature.properties.geometry };
						// Add the clu to the selected list, will also remove it if it already exists
						SetSelectedCLU(cluItem);
					});
				}
			}

			cluLayer.setStyle(cluStyler);

			return () =>
			{
				cluLayer.off('click');
			};
		}
		else if (MapMode.mode === MapInteractionMode.Drawing && SelectedCLUs && SelectedCLUs.length > 0)
		{
			const cluBoundaries: Feature<Geometry, ICLUItem>[] = [];
			for (let i = 0, len = SelectedCLUs.length; i < len; i++)
			{
				const cluField = SelectedCLUs[i];
				if (cluField.geometry)
				{
					cluBoundaries.push(feature(cluField.geometry, cluField));
				}
			}
			if (cluBoundaries.length > 0)
			{
				cluLayer.addData(featureCollection(cluBoundaries)) as L.GeoJSON<any>;

				cluLayer.setStyle(cluStyler);
			}
		}
	}, [mapRef.current, MapMode, cluData, IsLoadingCLUData, SelectedCLUs]);

	// Display Soil Data on the soil data layer
	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		soilDataLayer.clearLayers();

		if (!IsLoadingSoilData && popUpField && fieldSoilData[popUpField.Id])
		{
			// Load the field's soil data boundaries
			const soilDataBoundaries: FeatureCollection<Geometry> = fieldSoilData[popUpField.Id];
			if (soilDataBoundaries)
			{
				if (soilDataLayer.getLayers().length === 0)
				{
					soilDataLayer.addData(soilDataBoundaries) as L.GeoJSON<any>;
				}
			}

			soilDataLayer.setStyle(soilDataStyler);

			return () =>
			{
				soilDataLayer.off('click');
			};
		}
	}, [mapRef.current, MapMode, fieldSoilData, IsLoadingSoilData, popUpField]);

	const checkDrawingToolsDisplay = () =>
	{
		const drawingToolsVisible = mapRef.current.pm.controlsVisible();

		if (MapMode.mode !== MapInteractionMode.Drawing && drawingToolsVisible)
		{
			// hide the drawing tools
			mapRef.current.pm.toggleControls();
		}
		else if (MapMode.mode === MapInteractionMode.Drawing && !drawingToolsVisible)
		{
			// show the tools while drawing
			mapRef.current.pm.toggleControls();
		}
	};

	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		checkDrawingToolsDisplay();

		mapRef.current.on('popupclose', () =>
		{
			setShowSoilMapButton(true);
			soilDataLayer.clearLayers();
		});

		mapRef.current.on('popupopen', () =>
		{
			soilDataLayer.clearLayers();
		});

		// Drawn fields were saved and need to be removed from the map layers
		if (MapMode.mode === MapInteractionMode.Drawing && MapMode.drawingMode === DrawingInteractionMode.SaveCleanUp)
		{
			const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
			drawnLayers.forEach(drawnLayer =>
			{
				drawnLayer.remove();
			});

			ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
		}
		else if (MapMode.mode === MapInteractionMode.Selection && !DrawnFeatures)
		{
			const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
			drawnLayers.forEach(drawnLayer =>
			{
				drawnLayer.remove();
			});
		}

		// When a user starts drawing
		mapRef.current.on('pm:drawstart', (e: { shape: string; workingLayer: L.Layer; }) =>
		{
			// Turn off dragging temporarily so iPad can draw rectangles and circles
			if (mapRef.current.dragging.enabled())
			{
				mapRef.current.dragging.disable();
			}
		});

		mapRef.current.on('pm:drawend', e =>
		{
			// Re-enable dragging
			if (!mapRef.current.dragging.enabled())
			{
				mapRef.current.dragging.enable();
			}
		});

		mapRef.current.on('pm:globaldragmodetoggled', (e: { enabled: boolean; map: L.Map; }) =>
		{
			// If is enabled, change the drawing mode
			if (e.enabled)
			{
				if ((MapMode as IDrawingInterfaceMode).mode !== MapInteractionMode.Drawing && (MapMode as IDrawingInterfaceMode)?.drawingMode !== DrawingInteractionMode.Nothing)
				{
					ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
				}
			}
			else
			{
				// If it is disabled, save the edited features!
				saveDrawnFeatures();
			}
		});

		// Fires on finishing a polygon/circle/rectangle draw action
		mapRef.current.on('pm:create', (e: { shape: string; layer: L.Layer; }) => 
		{
			// Re-enable dragging
			if (!mapRef.current.dragging.enabled())
			{
				mapRef.current.dragging.enable();
			}
			saveDrawnFeatures();
		});

		// Fires on toggling the delete/remote button
		mapRef.current.on('pm:globalremovalmodetoggled', (e: { enabled: boolean; map: L.Map; }) =>
		{
			// If is enabled, change the drawing mode
			if (e.enabled)
			{
				ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Deleting });
			}
			else
			{
				// If it is disabled, save the edited features!
				saveDrawnFeatures();
			}
		});

		// Fires on finishing cutting a polygon into a drawn poly/circle/rect
		mapRef.current.on('pm:cut', (e: { shape: string; layer: L.Layer; }) =>
		{
			saveDrawnFeatures();
		});

		// Fires on toggling the Edit button
		mapRef.current.on('pm:globaleditmodetoggled', (e: { enabled: boolean; map: L.Map; }) => 
		{
			// If Editing is enabled, change the drawing mode
			if (e.enabled)
			{
				if ((MapMode as IDrawingInterfaceMode).mode !== MapInteractionMode.Drawing && (MapMode as IDrawingInterfaceMode)?.drawingMode !== DrawingInteractionMode.Nothing)
				{
					ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
				}
			}
			else
			{
				// If it is disabled, save the edited features!
				saveDrawnFeatures();
			}
		});

		// Fires on toggling the Rotate button
		mapRef.current.on('pm:globalrotatemodetoggled', (e: { enabled: boolean; map: L.Map; }) =>
		{
			// If rotate is enabled, change the drawing mode
			if (e.enabled)
			{
				if ((MapMode as IDrawingInterfaceMode).mode !== MapInteractionMode.Drawing && (MapMode as IDrawingInterfaceMode)?.drawingMode !== DrawingInteractionMode.Nothing)
				{
					ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
				}
			}
			else
			{
				// If it is disabled, save the edited features!
				saveDrawnFeatures();
			}
		});

		geoJsonLayer.setStyle(styler);

		return () =>
		{
			if (!mapRef.current)
			{
				return;
			}

			mapRef.current.off('pm:drawstart');
			mapRef.current.off('pm:drawend');
			mapRef.current.off('pm:create');
			mapRef.current.off('pm:globalremovalmodetoggled');
			mapRef.current.off('pm:globaleditmodetoggled');
			mapRef.current.off('pm:globaldragmodetoggled');
			mapRef.current.off('pm:cut');
			mapRef.current.off('pm:globalrotatemodetoggled');
		};

	}, [mapRef.current, mapContainer, MapMode, DrawnFeatures]);

	const onEnableCLU = async () =>
	{
		mapRef.current?.pm.disableDraw();

		if (mapRef.current.getZoom() < 14)
		{
			// Pop a notification alerting the user that they need to zoom closer to use CLU
			openNotificationWithIcon('error');
			return;
		}

		const mapBounds = mapRef.current.getBounds();
		ChangeMapMode({ mode: MapInteractionMode.CLU });
		const cluResponse = await DownloadCLUs({ top: mapBounds.getNorth(), left: mapBounds.getWest(), bottom: mapBounds.getSouth(), right: mapBounds.getEast() });
		if (cluResponse)
		{
			setCluData(cluResponse.payload as ICLUItem[]);
		}
	};

	const openNotificationWithIcon = (type: string) =>
	{
		notification[type]({
			message: 'Zoom Closer',
			duration: 3.5, // seconds to display - default is 4.5
			description:
				'Zoom in closer to display the CLU boundaries.',
			placement: 'topRight' // A notification box can appear from the topRight, bottomRight, bottomLeft or topLeft of the viewport.
		});
	};

	const cancelCLU = () =>
	{
		ClearCluState();
		setCluData([]);
		ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
	};

	const onSaveCLU = () =>
	{
		setCluData([]);
		saveDrawnFeatures();
		ChangeMapMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
	};

	const onLoadSoilMap = async (field: IFieldResponse) =>
	{
		// If we have not already downloaded this field
		if (!fieldSoilData[field.Id])
		{
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			selectedFieldBoundaries.push(feature(field.Boundary, field));
			const collection: FeatureCollection<Geometry, IFieldResponse> = featureCollection(selectedFieldBoundaries);
			const soilDataResponse = await DownloadSoilMap(collection);
			if (soilDataResponse)
			{
				if (fieldSoilData[field.Id])
				{
					fieldSoilData[field.Id] = (soilDataResponse.payload as ISoilDataResponse).data;
					setFieldSoilData({ ...fieldSoilData });
				}
				else
				{
					setFieldSoilData({ ...fieldSoilData, [field.Id]: (soilDataResponse.payload as ISoilDataResponse).data });
				}
			}
		}
		else
		{
			setFieldSoilData({ ...fieldSoilData });
		}

		setShowSoilMapButton(false);
	};

	const showFieldDetails = () =>
	{
		soilDataLayer.clearLayers();
		setShowSoilMapButton(true);
	};

	const getFieldSoilData = ():IFieldSoilData[] =>
	{
		const currentField = popUpField;
		const allFieldSoilData: IFieldSoilData[] = [];
		if (!showSoilMapButton && currentField && fieldSoilData[currentField.Id])
		{
			const soilData = [ ...fieldSoilData[currentField.Id].features ];
			soilData.forEach(sd =>
			{
				const area = Number.parseFloat(sd.properties.area);
				const percentOfTotal = ((area / currentField.Area ) * 100).toFixed(1);
				const singleSoilSectionData: IFieldSoilData =
				{
					Acres: sd.properties.area ? sd.properties.area.toFixed(1) : '0',
					Color: colorToHex(sd.properties.color),
					MapUnit: sd.properties.mukey,
					OverallNccpi: sd.properties.nccpi ? sd.properties.nccpi.Overall.toFixed(2) : 'none',
					PercentOfTotal: percentOfTotal,
					SoilType: sd.properties.soil_type,
				};

				allFieldSoilData.push(singleSoilSectionData);
			});
		}

		return allFieldSoilData;
	};

	const onCenterAll = useCallback(() =>
	{
		// Padding will scoot the map over a bit to give space for the overlay on the left
		mapRef.current.fitBounds(geoJsonLayer.getBounds(), { paddingTopLeft: [400, 0] });
	}, [mapRef.current, geoJsonLayer]);

	const onCenterSelected = useCallback(() =>
	{
		if (SelectedFieldIds && SelectedFieldIds.length > 0)
		{
			// Get all of the boundaries
			// There are some cases where the Fields have no Boundary, this may only be during
			// early development
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			for (let i = 0, len = SelectedFieldIds.length; i < len; i++)
			{
				const field = allGrowerFields.find(f => f.Id === SelectedFieldIds[i]);
				if (field && field.Boundary)
				{
					selectedFieldBoundaries.push(feature(field.Boundary, field));
				}
			}
			if (selectedFieldBoundaries.length > 0)
			{
				const collection = featureCollection(selectedFieldBoundaries);
				const group = L.geoJSON(collection);
				// Padding will scoot the map over a bit to give space for the overlay on the left
				mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [450, 0] });

				geoJsonLayer.setStyle(styler);
			}
		}
		else if (SelectedFieldForEdit)
		{
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			const field = allGrowerFields.find(f => f.Id === SelectedFieldForEdit);
			if (field && field.Boundary)
			{
				selectedFieldBoundaries.push(feature(field.Boundary, field));
			}
			if (selectedFieldBoundaries.length > 0)
			{
				const collection = featureCollection(selectedFieldBoundaries);
				const group = L.geoJSON(collection);
				// Padding will scoot the map over a bit to give space for the overlay on the left
				mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [450, 0] });
			}
		}
	}, [SelectedFieldIds, SelectedFieldForEdit, allGrowerFields, mapRef.current]);

	const toggleLocationSearch = useCallback(() =>
	{
		SetShowMapSearch(!DisplayMapSearch);
	}, [mapRef.current]);

	const geocoder = new MapboxGeocoder({
		accessToken: MapboxToken,
		types: 'address,region,place,postcode,locality,neighborhood',
		countries: 'us,ca'
	});

	useEffect(() => 
	{
		// Get geocoder result to move the map.
		geocoder.on('result', (e: { result: MapboxGeocoder.Result; }) =>
		{
			const results = e.result;
			if (results.place_type[0] === 'address')
			{
				// Perform some acrobatics to convert the point into a fake marker and then get some bounds
				// so that we can add some padding on the left to scoot it over into the center of the right view!
				const resultPosition: LatLngExpression = [results.center[1], results.center[0]];
				const tempMarker: L.Marker = L.marker(resultPosition);
				const tempMarkerLatLngArr = [tempMarker.getLatLng()];
				const bounds = L.latLngBounds(tempMarkerLatLngArr);
				mapRef.current.fitBounds(bounds, { paddingTopLeft: [450, 0] });
			}
			else
			{
				// Anything not an address should land here! Just use the bbox to make some bounds
				mapRef.current.fitBounds([
					[results.bbox[3], results.bbox[0]],
					[results.bbox[1], results.bbox[2]]
				], { paddingTopLeft: [450, 0] });
			}
		});

	}, [geocoder, mapRef.current]);

	const [fieldWeatherData, setFieldWeatherData] = useState<Record<string, IWeatherSnapshot[]>>({});
	const [growthStageData, setGrowthStageData] = useState<Record<string, IGrowthStageData>>({});
	useEffect(() => 
	{
		const api = new Api('/api/6',  AuthToken, undefined);

		async function fetchWeather()
		{
			const response = await api.getAsync<{FieldId: string, Samples: IWeatherSnapshot[]}>(
				`fields/${popUpField.Id}/wx`
			);

			setFieldWeatherData(prev => ({...prev, [popUpField.Id]:response.Data.Samples }));
		}

		async function fetchGrowthStages()
		{
			const response = await api.getAsync<IGrowthStageData>(
				`fields/${popUpField.Id}/years/${new Date().getFullYear()}/growthstages`
			);			
			setGrowthStageData(prev => ({...prev, [popUpField.Id]:response.Data }));
		}

		if(!popUpField?.Id)
		{
			return;
		}

		// Check if we have weather data already
		if(fieldWeatherData[popUpField.Id] === undefined)
		{
			setFieldWeatherData(prev => ({...prev, [popUpField.Id]:[] }));
			// download it and save it
			fetchWeather();
			fetchGrowthStages();
		}
	}, [
		popUpField?.Id,
		SelectedGrowerId,
		SelectedUserId,
		AuthToken,
		setFieldWeatherData,
		setGrowthStageData
	]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<div id='mapId' ref={installMap} style={{ width: '100%', height: '100%' }} />
			<MapPopUp
				reference={popupRef}
				field={popUpField}
				farmName={popUpFarmName}
				loadSoilMap={onLoadSoilMap}
				showSoilMapButton={showSoilMapButton}
				showFieldDetails={showFieldDetails}
				fieldSoilData={getFieldSoilData()}
				weatherData={fieldWeatherData[popUpField?.Id]}
				growthStageData={growthStageData[popUpField?.Id]}
			/>
			<div id='geocoder' style={{ position: 'absolute', top: 73, left: 'calc(100vw - 307px)', zIndex: 998, display: !DisplayMapSearch ? 'none' : 'inherit' }} />
			<DrawingTray
				mapRef={mapRef}
				onEnableCLU={onEnableCLU}
				onCancelCLU={cancelCLU}
				onSaveCLU={onSaveCLU}
				isMapModeCLU={MapMode.mode === MapInteractionMode.CLU}
				displayTools={MapMode.mode !== MapInteractionMode.Selection}
			/>
			<ZoomTray
				mapRef={mapRef}
				onCenterAll={onCenterAll}
				onCenterSelected={onCenterSelected}
				toggleLocationSearch={toggleLocationSearch}
				displayTools={SelectedGrowerId ? true : false}
				geocoder={geocoder}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	DisplayMapSearch: state.ui.ShowMapSearch,
	DrawnFeatures: state.ui.DrawnFeatures,
	Growers: state.grower.Growers,
	IsDeletingField: state.grower.isDeletingField,
	IsLoadingCLUData: state.clu.isLoading,
	IsLoadingFields: state.grower.isLoadingFarms,
	IsLoadingSoilData: state.soilData.isLoading,
	IsSavingField: state.grower.isSavingField,
	MapboxToken: state.config.MapboxAccessToken,
	MapMode: state.ui.MapInteractionMode,
	SelectedCLUs: state.clu.selectedCLUs,
	SelectedFieldIds: state.ui.SelectedFields,
	SelectedFieldForEdit: state.ui.SelectedFieldForEdit,
	SelectedGrowerId: state.ui.SelectedGrowerId,
	SelectedUserId: getCurrentActingUser(state).UserId,
	AuthToken: state.auth?.userAuthToken
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ChangeMapMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
		ClearAllSelectedFields: () => dispatch(clearSelectedFields()),
		ClearCluState: (() => dispatch(clearCluState())),
		DownloadCLUs: (request: ICLURequest) => dispatch(getCLUs(request)),
		DownloadSoilMap:  ((value: FeatureCollection<Geometry, IFieldResponse>) => dispatch(getSoilData(value))),
		SelectSingleField: (fieldId: string) => dispatch(addSingleSelectedField(fieldId)),
		SetSelectedCLU: (item: ICLUItem) => dispatch(addSelectedCLU(item)),
		SetShowMapSearch: ((value: boolean) => dispatch(setShowMapSearch(value))),
		StoreDrawnFeatures: (features: FeatureCollection<Polygon>) => dispatch(setDrawnFeatures(features)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Map = connector(MapComponent);