import React, { useEffect, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import { Map } from '../../logic/Map/Map';
import { SideMenuBar } from './SideMenuBar';
import { GrowerList } from './GrowerListView/GrowerList';
import { FieldListDrawer } from './FieldListDrawer';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { getFarms, IFarmRequest } from '../../logic/store/Grower/FarmThunks';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Recall } from './Recall';
import { ConnectedProductGamePlan } from './ProductGamePlan/ProductGamePlan';
import { getCropYear } from '../../logic/store/Seeds/CropsSlice';
import { getGrowers } from '../../logic/store/Grower/GrowerThunks';
import { IGrowerListRequest } from '../../logic/Models/Requests/GrowerRequest';
import { ProductShoppingCart } from './ShoppingCart/ProductShoppingCart/ProductShoppingCart';
import { FieldQuote } from './ShoppingCart/FieldShoppingCart/FieldShoppingCart';
import { VRSMainComponent } from './VRS/VRSMain';
import { MaxScriptMainComponent } from './MaxScript/MaxScriptMain';
import { CropEdgeView } from './CropEdgeIFramePage/CropEdgeView';
import { importGrowerOrderData } from '../../logic/store/Grower/OrderThunks';
import { makeDispatch } from '../../logic/Utility/Utils';
import { selectGrowerAndPullSeeds } from '../../logic/store/UI/UIThunks';
import { BulkImport } from './BulkImport/BulkImport';
import { ConnectedFieldPlan } from './FieldPlan/FieldPlan';
import { getCurrentActingUser, getManageableUsers } from '../../logic/store/User/AuthSlice';
import { CompetitiveAllowance } from './CompetitiveAllowance/CompetitiveAllowanceMain';
import { CropwiseImport } from './CropwiseImport/CropwiseImport';

interface IFieldActivitiesProps extends PropsFromRedux
{

}

const FieldActivitiesComponent = (props: IFieldActivitiesProps) =>
{
	const {
		Growers,
		IsLoadingFarms,
		SelectedGrowerId,
		GetGrowerOrderData,
		PullFarms,
		SetSelectedGrowerId,
	} = props;

	const selectedGrower = useMemo(() => Growers.find(g => g.Id === SelectedGrowerId), [Growers, SelectedGrowerId]);
	
	/**
	 * React Router - get location props
	 * planId comes from the Recall page when a user clicks on the Quote icon on an 'Accepted' plan row
	 */
	const location = useLocation<{ planId: string, selectedYear: string, orderName: string }>();
	const { state } = location;

	useEffect(() =>
	{
		// Don't pull farms in competitive allowance
		if ((location?.pathname as string).includes('competitiveallowance'))
		{
			return;
		}
		if (selectedGrower && !IsLoadingFarms)
		{
			PullFarms({ growerId: SelectedGrowerId });
		}
	}, [SelectedGrowerId]);

	const setSelectedGrower = (growerId: string) =>
	{
		// Perform additional grower-setting logic here
		SetSelectedGrowerId(growerId);
	};

	const importOrder = (year: string) =>
	{
		GetGrowerOrderData({year});
	};
	return (
		<div style={{ height: '100%' }}>
			{SelectedGrowerId
				? <SideMenuBar />
				: <></>
			}
			<Switch>
				<Route path='/fieldactivities/recall'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/recall'} to={'/fieldactivities'} />
						: <Recall selectedGrower={selectedGrower} importOrder={importOrder}/>
					}
				</Route>
				<Route path='/fieldactivities/productshoppingcart'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/productshoppingcart'} to={'/fieldactivities'} />
						: <ProductShoppingCart selectedPlanId={state?.planId} selectedGrower={selectedGrower} />
					}
				</Route>
				<Route path='/fieldactivities/fieldshoppingcart'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/fieldshoppingcart'} to={'/fieldactivities'} />
						: <FieldQuote selectedPlanId={state?.planId} selectedGrower={selectedGrower} />
					}
				</Route>
				<Route path='/fieldactivities/productplan'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/productplan'} to={'/fieldactivities'} />
						: <ConnectedProductGamePlan selectedGrower={selectedGrower} />
					}
				</Route>
				<Route path='/fieldactivities/VRS'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/VRS'} to={'/fieldactivities'} />
						: <VRSMainComponent selectedGrower={selectedGrower} selectedYear={state?.selectedYear} />
					}
				</Route>
				<Route path='/fieldactivities/maxscript'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/maxscript'} to={'/fieldactivities'} />
						: <MaxScriptMainComponent selectedOrderId={state?.planId} selectedGrower={selectedGrower} selectedYear={state?.selectedYear} />
					}
				</Route>
				<Route path='/fieldactivities/cropedgeview'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/cropedgeview'} to={'/fieldactivities'} />
						: <CropEdgeView orderName={state?.orderName} />
					}
				</Route>
				<Route path='/fieldactivities/bulkimport'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/bulkimport'} to={'/fieldactivities'} />
						: <BulkImport />
					}
				</Route>
				<Route path='/fieldactivities/cropwiseimport'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/cropwiseimport'} to={'/fieldactivities'} />
						: <CropwiseImport />
					}
				</Route>
				<Route path='/fieldactivities/fieldplan'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/fieldplan'} to={'/fieldactivities'} />
						: <ConnectedFieldPlan />
					}
				</Route>
				<Route path='/fieldactivities/competitiveallowance'>
					{!SelectedGrowerId
						? <Redirect from={'/fieldactivities/competitiveallowance'} to={'/fieldactivities'} />
						: <CompetitiveAllowance />
					}
				</Route>
				<Route path={['*']} >
					{SelectedGrowerId
						? <FieldListDrawer />
						: <GrowerList growers={Growers} onSelectGrower={setSelectedGrower} />
					}
					<Map />
				</Route>
			</Switch>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	CurrentCropYear: state.crops.CropYear,
	Growers: state.grower.Growers,
	IsCurrentCropYearLoading: state.crops.isLoading,
	IsGrowerLoading: state.grower.isLoading,
	IsLoadingFarms: state.grower.isLoadingFarms,
	SelectedGrowerId: state.ui.SelectedGrowerId,
	CurrentActingUser: getCurrentActingUser(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetCurrentCropYear: () => dispatch(getCropYear()),
		GetGrowerOrderData: makeDispatch(dispatch, importGrowerOrderData),
		GetGrowers: (request: IGrowerListRequest) => dispatch(getGrowers(request)),
		PullFarms: (request: IFarmRequest) => dispatch(getFarms(request)),
		SetSelectedGrowerId: (growerId: string) => dispatch(selectGrowerAndPullSeeds(growerId)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldActivities = connector(FieldActivitiesComponent);


